import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import pic04 from '../assets/images/img.png'
import Header from '../components/Header'
import { Link } from 'gatsby'

class Bestattungen extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="Hilfe im Trauerfall | Bestattungshaus Bartko" />

        <Header isChildPage={true} />
        <div id="main">
          <section id="content" className="main">
            <span className="image main"><img src={pic04} alt="" /></span>

            <h1>Hilfe im Trauerfall</h1>
            <p>
              Folgende Punkte sollten Sie im Trauerfall schnellstmöglich erledigen
            </p>
            <ul>
              <li>Hausarzt oder Notarzt (&#9742; 112) oder den ärztlichen Notdienst (&#9742; 116 117) verständigen</li>
              <li>Personalausweis der verstorbenen Person bereithalten</li>
              <li>Mit einem Bestattungsunternehmen Ihres Vertrauens Kontakt aufnehmen</li>
            </ul>

            <p>Sie benötigen folgende Dokumente der verstorbenen Person zur Vorlage beim Bestatter:</p>
            <ul>
              <li>Personalausweis</li>
              <li>bei Familienstand:</li>

              <li>ledig</li>
              <ul>
                <li>Geburtsurkunde</li>
              </ul>

              <li>verheiratet</li>
              <ul>
                <li>Heiratsurkunde oder Familienstammbuch</li>
                <li>Geburtsurkunde beider Ehepartner</li>
              </ul>

              <li>verwitwet</li>
              <ul>
                <li>Heiratsurkunde oder Familienstammbuch</li>
                <li>Geburtsurkunde beider Ehepartner</li>
                <li>Sterbeurkunde des verstorbenen Ehepartners</li>
              </ul>

              <li>geschieden</li>
              <ul>
                <li>Heiratsurkunde oder Familienstammbuch</li>
                <li>Geburtsurkunde</li>
                <li>Scheidungsurteil mit Rechtskraftvermerk</li>
              </ul>

              <li>eingetragene Lebenspartnerschaft</li>
              <ul>
                <li>Lebenspartnerschaftsurkunde oder Familienstammbuch</li>
                <li>Geburtsurkunde beider Partner</li>
              </ul>

              <li>ggf. Bestattungsvorsorgevereinbarung</li>
              <li>Rentenanpassungsmitteilung</li>
              <li>Chipkarte der Krankenkasse</li>
              <li>Schwerbehindertenausweis</li>
              <li>ggf. Police der Sterbegeld- oder Lebensversicherung</li>
              <li>Nachweis über weitere Bezüge (z.B. Blindengeld, Betriebsrente)</li>
            </ul>
            <p>Sollten Urkunden - aus welchen Gründen auch immer - nicht zur Verfügung stehen, so helfen wir gern bei deren Beschaffung.</p>

            <center>
              <Link to="/" className="button">
                Zurück zur Übersicht
              </Link>
            </center>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Bestattungen
